import React from 'react';
import SEO from '../components/core/meta/SEO';
import PageHeader from '../components/views/PageHeader/PageHeader';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import { graphql, useStaticQuery } from 'gatsby';
import { CurePageQuery } from '../../generated/graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../components/core/buttons/Button';

const CurePage = () => {
  const cureData = useStaticQuery<CurePageQuery>(graphql`
    query CurePage {
      headerBackground: file(relativePath: { eq: "headers/Rainbow.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      nonSiFerma: file(relativePath: { eq: "nonsiferma.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      roche: file(relativePath: { eq: "logo/partners/Roche.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      rocheugo: file(relativePath: { eq: "logo/rocheugo.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
        }
      }
      rochesupp: file(relativePath: { eq: "logo/partners/rochesupp.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  `);
  return (
    <>
      <SEO
        title="Servizio di accompagnamento e trasporto anziani"
        url="/lacuranonsiferma"
      />
      {/*<PageHeader*/}
      {/*  backgroundImage={cureData.headerBackground.childImageSharp.fluid}*/}
      {/*>*/}
      {/*  <Container>*/}
      {/*    <Heading variant="h1" className="text-white">*/}
      {/*      Insieme per offrire l`accompagnamento a visite e terapie e il*/}
      {/*      supporto nelle commissioni*/}
      {/*    </Heading>*/}
      {/*  </Container>*/}
      {/*</PageHeader>*/}
      <Container className="py-16 text-center">
        <Heading variant="h1">#Lacuranonsiferma</Heading>
        <p className="text-paragraph mb-6">
          #Lacuranonsiferma è il progetto di Roche e UGO nato durante il periodo
          di pandemia da Covid-19 con l&apos;obiettivo di supportare pazienti in
          cura nel continuare a seguire le terapie nonostante le dfifficoltà del
          momento. Il progetto ha permesso di erogare olter 2.000 servizi
          gratuiti in tutta Italia e di affiancare 150 pazienti e le loro
          famiglie.
        </p>
        <p className="text-paragraph mb-10">
          <b>
            Il progetto in collaborazione con Roche è terminato in data
            31/03/2021
          </b>
        </p>
        <p className="text-3xl font-bold mb-4">Ma UGO non si ferma</p>
        <p className="text-paragraph mb-6">
          Il servizio UGO è attivo sul territorio e continua a rappresentare un
          supporto quotidiano per pazienti e utenti con fragilità.
        </p>
        <a
          href="https://hellougo.typeform.com/to/M60Cma4J"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant="filled"
            color="primary"
            // className="px-20 text-3xl font-bold"
            style={{
              paddingLeft: '5rem',
              paddingRight: '5rem',
              fontSize: '1.875rem',
              lineHeight: '2.25rem',
              fontWeight: '700',
            }}
          >
            Richiedi informazioni
          </Button>
        </a>

        <div className="flex">
          <GatsbyImage
            image={cureData.rochesupp.childImageSharp.gatsbyImageData}
            alt="roche supporto"
            className="mx-auto max-w-full"
          />
        </div>
      </Container>
    </>
  );
};

export default CurePage;
